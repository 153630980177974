import { type Property } from '@ev/search-modules-api';
import { useBreakpoint } from '@pkgs/components/hooks/useBreakpoint';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { type AriaButtonProps } from 'react-aria';

import { ActionCard } from '../ActionCard/ActionCard';
import { Pagination } from '../Pagination/Pagination';
import { SearchAlertCtaCard } from '../SearchAlertCtaCard/SearchAlertCtaCard';
import { LoadingResultCard } from '../Skeleton/LoadingResultCard';
import { SearchResultCardWrapper } from './SearchResultCardWrapper';
import { StyledPaginationContainer, StyledResultsList, StyledResultsListContainer } from './SearchResults.styled';

type Props = {
  triggerProps: AriaButtonProps<'button'>;
  isMapView: boolean;
};

export const SearchResultList = ({ triggerProps, isMapView }: Props) => {
  const {
    isPartnerShops,
    updateOptions,
    results,
    options: { page },
    isFetching,
  } = useSearch();
  const { isTabletPortrait, isTabletLandscape, isMobile } = useBreakpoint();

  const isTabletOrMapView = isTabletPortrait || isTabletLandscape || isMapView;

  let ctaCardPosition = isMobile ? 2 : isTabletOrMapView ? 3 : 5;
  ctaCardPosition = Math.min(ctaCardPosition, results.hits.length);

  const hitsWithCtaCard = [
    ...results.hits.slice(0, ctaCardPosition),
    results.hits[ctaCardPosition],
    ...results.hits.slice(ctaCardPosition),
  ] as Property[];

  const blankCardItems = Array.from({ length: 18 }).fill({}) as Property[];

  const displayedHits: Property[] = isFetching ? blankCardItems : hitsWithCtaCard;

  return (
    <StyledResultsListContainer>
      <StyledResultsList $isMapView={isMapView}>
        {displayedHits.map((property, index) => {
          if (isFetching) return <LoadingResultCard key={index} />;
          if (index === ctaCardPosition) {
            if (isPartnerShops) {
              return <ActionCard key={`action-card-${index}`} />;
            }
            return <SearchAlertCtaCard key={index} {...triggerProps} />;
          }
          return <SearchResultCardWrapper preloadImage={index < 6} key={property.id} property={property} index={index} />;
        })}
      </StyledResultsList>

      {results.totalPages > 1 && (
        <StyledPaginationContainer>
          <Pagination numPages={results.totalPages} currentPage={page} onPageChange={(page) => updateOptions({ page })} />
        </StyledPaginationContainer>
      )}
    </StyledResultsListContainer>
  );
};
