import { type AutoSuggestionPlaceResult, commonSuggest, type CommonSuggestParams, PlaceType } from '@generated/search-bff';
import { type ApiConfig } from '@pkgs/api/types';
import { type ApiResult, type GetLocationSuggestionOptions } from '@pkgs/api/types';
import { createSessionToken } from '@pkgs/api/utils';
import { getErrorResult } from '@pkgs/api/utils/error';

/**
 * Fetch location suggestions based on the provided query and options.
 * @function
 * @param {string} query - The location or place string representing a complete or partial city, neighborhood, postal code, etc.
 * @param {GetLocationSuggestionOptions} options - Configuration options for the suggestion fetch operation,
 * including optional session token, limit, and types of places to include in the results.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<AutoSuggestionPlaceResult[]>>} A promise that resolves to either location suggestions or error details.
 *
 * @summary Fetch location suggestions based on the provided query and options.
 */
export async function getLocationSuggestions(
  query: string,
  options: GetLocationSuggestionOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<AutoSuggestionPlaceResult[]>> {
  try {
    const suggestPayload: CommonSuggestParams = {
      ...options,
      sessionToken: options.sessionToken ?? createSessionToken(),
      input: query,
      limit: options.limit ?? 10,
      types: options.types ?? [
        PlaceType.country,
        PlaceType.neighborhood,
        PlaceType.locality,
        PlaceType.administrative_area_level_1,
        PlaceType.administrative_area_level_4,
        PlaceType.postal_code,
        PlaceType.sublocality,
      ],
    };

    const results = await commonSuggest(suggestPayload, apiConfig);

    return {
      status: 'success',
      data: results,
    };
  } catch (error) {
    return getErrorResult(error);
  }
}
