import {
  confirm
} from "./chunk-YYWHXEGH.js";
import {
  getErrorResult
} from "./chunk-TLK7Y73O.js";

// src/confirmSearchAlert/confirmSearchAlert.ts
async function confirmSearchAlert(searchAlertId, apiConfig) {
  try {
    await confirm(searchAlertId, apiConfig);
    return { status: "success", data: void 0 };
  } catch (error) {
    return getErrorResult(error);
  }
}

export {
  confirmSearchAlert
};
