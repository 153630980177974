import { fullsizePseudo } from '@pkgs/components/utils/fullsizePseudo';
import { from } from '@pkgs/components/utils/mediaQueries';
import { css, styled } from 'styled-components';

import { focusStyleDark } from '../../utils/focusStyle';
import { lineClamp } from '../../utils/lineClamp';
import { Copy } from '../Copy/Copy';
import { FavoriteButton } from '../FavoriteButton/FavoriteButton';
import { Headline } from '../Headline/Headline';
import { type SearchResultsCardVariant } from './SearchResultCard';

export const StyledFavourite = styled(FavoriteButton)`
  &&& {
    position: absolute;
    top: var(--distance-static-s);
    right: var(--distance-static-s);
    padding: 0;
    z-index: var(--zindex-m);
    background-color: transparent;
    > svg {
      transition: transform 0.5s ease;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
`;

export const StyledHeadline = styled(Headline)<{
  $variant: SearchResultsCardVariant;
}>`
  margin-bottom: var(${({ $variant }) => ($variant === 'preview' ? '--distance-static-xxs' : '--distance-static-xs')});
  color: currentColor;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledLocation = styled(Copy)`
  margin-bottom: var(--distance-static-3xs);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-text-dark-subdued);
`;

export const StyledPriceContainer = styled.div<{
  $variant: SearchResultsCardVariant;
}>`
  margin-bottom: var(${({ $variant }) => ($variant === 'preview' ? '--distance-static-xxs' : '--distance-static-xs')});
`;

export const StyledPriceLabel = styled(Copy)`
  color: var(--color-text-dark-subdued);
`;

export const StyledPriceValueContainer = styled.div`
  display: inline;
  vertical-align: baseline;
  & > *:not(:last-child) {
    margin-right: var(--distance-static-xxs);
  }
`;

export const StyledPrice = styled(Copy)`
  color: var(--color-text-dark-highlight);
  display: inline;
`;

export const StyledConvertedPrice = styled(Copy)`
  color: var(--color-text-dark-subdued);
  display: inline;
`;

export const StyledAttributes = styled.ul<{
  $variant: SearchResultsCardVariant;
}>`
  min-height: var(--distance-static-l);
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  ${({ $variant }) => $variant === 'preview' && lineClamp(2)};
`;

export const StyledAttributeItem = styled(Copy)`
  display: inline-block;
`;

export const StyledAttribute = styled.li`
  color: var(--color-text-dark-subdued);
  white-space: nowrap;

  &::after {
    content: '·';
    margin: 0 var(--distance-static-xxs);
  }

  &:last-child::after {
    content: '';
  }
`;

export const StyledContent = styled.div<{ $variant: SearchResultsCardVariant }>`
  padding: var(--distance-static-s);

  @media screen and (${from.tabletLandscape}) {
    ${({ $variant }) =>
      $variant === 'default' &&
      css`
        padding: var(--distance-static-m);
      `}
  }
`;

export const StyledArticle = styled.article<{
  $variant: SearchResultsCardVariant;
}>`
  position: relative;
  cursor: pointer;
  height: 100%;

  ${focusStyleDark}

  background-color: var(--color-surface-light);
  box-shadow: var(--shadow-xs);
  border-radius: var(--border-radius-s);
  color: var(--color-link-pimary);
  height: 100%;
  transition:
    box-shadow 0.2s ease,
    color 0.2s ease,
    background-color 0.2s ease;

  @media (hover: hover) {
    &:hover {
      box-shadow: var(--shadow-s);
      color: var(--color-link-pimary-hover);
      --coursel-button-opacity: 1;
    }
  }

  &:active:not(:has(button:active, img:active)) {
    background-color: var(--color-surface-light-subdued);
    box-shadow: var(--shadow-xs);
    color: var(--color-link-pimary-active);
  }

  ${({ $variant }) =>
    $variant === 'preview' &&
    css`
      width: 260px;
    `};
`;

export const StyledImageWrapper = styled.div`
  position: relative;
`;

export const StyledBadgesContainer = styled.div`
  position: absolute;
  top: var(--distance-static-m);
  left: var(--distance-static-s);
  z-index: var(--zindex-m);
  display: flex;
  gap: var(--distance-static-xs);
`;

export const LinkContainer = styled.div`
  all: unset;
  & > a {
    ${fullsizePseudo};
  }
`;
