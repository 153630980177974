import {
  getDefaultParams
} from "./chunk-LN3ZTW4I.js";
import {
  postListingsByIds
} from "./chunk-PLW25FCF.js";
import {
  getErrorResult,
  refineProperty
} from "./chunk-TLK7Y73O.js";

// src/getPropertiesByListingIds/getPropertiesByListingIds.ts
async function getPropertiesByListingIds(listingIds, options, apiConfig) {
  if (listingIds.length === 0) {
    console.warn("listingIds is an empty array.");
  }
  try {
    const params = getDefaultParams(options);
    const listingsDtos = await postListingsByIds({ listingIds }, params, apiConfig);
    return {
      status: "success",
      data: listingsDtos.map((i) => refineProperty(i, options.language))
    };
  } catch (error) {
    return getErrorResult(error);
  }
}

export {
  getPropertiesByListingIds
};
