import { getPlaceDetailsV2 } from '@generated/search-bff';
import { type ApiConfig, type ApiResult, type GetPlaceLocationDetailsOptions } from '@pkgs/api/types';
import { type PlaceLocation } from '@pkgs/api/types/location';
import { createSessionToken } from '@pkgs/api/utils';
import { getErrorResult } from '@pkgs/api/utils/error';
import { getLocationFromPlaceDetails } from '@pkgs/api/utils/location';

/**
 * Fetch detailed location information for a given place ID.
 * @deprecated This is deprecated and will be removed soon. Please use getLocationDetails instead.
 * @function
 * @param {string} placeId - The unique identifier of the place to fetch details for.
 * @param {Language} GetPlaceLocationDetailsOptions.language - The language that the fetched location information should be in.
 * @param {string} GetPlaceLocationDetailsOptions.sessionToken - Session Token.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<PlaceLocation>>} A promise that resolves to either detailed location information or error details.
 *
 * @summary Fetch detailed location information for a given place ID.
 */

export async function getPlaceLocationDetails(
  placeId: string,
  { language, sessionToken }: GetPlaceLocationDetailsOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<PlaceLocation>> {
  try {
    const result = await getPlaceDetailsV2(
      {
        placeId,
        language,
        sessionToken: sessionToken ?? createSessionToken(),
      },
      apiConfig,
    );

    return {
      status: 'success',
      data: getLocationFromPlaceDetails(result),
    };
  } catch (error) {
    return getErrorResult(error);
  }
}
