import {
  listPropertiesForMap
} from "./chunk-PLW25FCF.js";
import {
  getErrorResult
} from "./chunk-TLK7Y73O.js";
import {
  mapFilters
} from "./chunk-SLAH73O2.js";

// src/getPropertiesCoordinates/getPropertiesCoordinates.ts
async function getPropertiesCoordinates(filters, options, apiConfig) {
  try {
    const data = await listPropertiesForMap(mapFilters(filters, options), apiConfig);
    return {
      status: "success",
      data
    };
  } catch (error) {
    return getErrorResult(error);
  }
}

export {
  getPropertiesCoordinates
};
