import {
  type BusinessArea,
  type CountryCode,
  type GetPropertiesFilters,
  type Property,
  type PropertyMarketingType,
  type PropertySubType,
  type PropertyType,
} from '@ev/search-modules-api';
import { initSnowplowTracker } from '@ev/snowplow-library';
import { AppContextProvider, type AppContextProviderProps } from '@pkgs/components/providers/AppContextProvider';
import { BreakpointProvider } from '@pkgs/components/providers/BreakpointProvider';
import { SearchProvider, type SearchProviderProps } from '@pkgs/components/providers/SearchProvider';
import { type WatchlistItem, WatchlistProvider } from '@pkgs/components/providers/WatchlistProvider';
import { hasUserAcceptedTracking, type PageType } from '@pkgs/components/utils/tracking';
import { SiteTrackingPlugin } from '@snowplow/browser-plugin-site-tracking';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import { ShopBanner } from '../Banners/ShopBanner/ShopBanner';
import { FilterBar } from '../FilterBar/FilterBar';
import { GlobalStyles } from '../GlobalStyles/GlobalStyles';
import { SearchResults } from '../SearchResults/SearchResults';
import { StyledStickyHeader, StyledWrapper } from './SearchModule.styled';

export type SearchModuleEvents = {
  onUrlUpdate?: (url: string) => void;
  onWatchlistClick?: (property: Property) => void;
  // TODO: remove event when we export search module provider
  onIsMapViewChange?: (isMapView: boolean) => void;
};

export type TrackingConfig = {
  snowplowApplicationId?: string;
  isSnowplowInitialized?: boolean;
  pageType?: PageType;
};

export type SearchModuleFilters = Omit<
  GetPropertiesFilters,
  'fallbackSearch' | 'businessArea' | 'marketingType' | 'propertyType' | 'propertySubType' | 'countryCode'
> & {
  // Override the types that aren't handled as arrays in search
  businessArea: BusinessArea;
  marketingType: PropertyMarketingType;
  propertyType?: PropertyType;
  propertySubType?: PropertySubType;
  countryCode?: CountryCode;
};

export type SeoPageLink = {
  text: string;
  url: string;
};

export type SearchModuleProps = Omit<SearchProviderProps, 'children' | 'onUrlUpdate'> &
  Omit<AppContextProviderProps, 'children'> & {
    events?: SearchModuleEvents;
    isShopBannerVisible?: boolean;
    watchlist?: WatchlistItem[];
    tracking?: TrackingConfig;
    isFilterBarSticky?: boolean;
    seoPagesLinks?: SeoPageLink[];
    infoRowVariant?: 'autogen' | 'search';
  };

export const SearchModule = ({
  initialFilters,
  initialOptions,
  initialPlaceDetails,
  initialResults,
  initialGeoResults,
  countryCode,
  translationResolver,
  syncStateWithUrl,
  events,
  apiConfig,
  imageComponent,
  linkComponent,
  searchAlertBaseUrl,
  watchlist,
  isShopBannerVisible = true,
  tracking,
  shopNames,
  currency,
  measurementSystem,
  language,
  mapTilerApiKey,
  isMapEnabled = false,
  friendlyCaptchaSiteKey,
  vercelProtectionBypassToken,
  mapBoundingBoxFallback,
  isFilterBarSticky,
  seoPagesLinks,
  infoRowVariant,
}: SearchModuleProps) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
    [],
  );

  useEffect(() => {
    if (tracking?.isSnowplowInitialized) return;

    if (!tracking?.snowplowApplicationId) {
      console.warn('Snowplow application ID is not provided.');
      return;
    }
    initSnowplowTracker({
      applicationId: tracking.snowplowApplicationId,
      hasUserAcceptedAgreement: hasUserAcceptedTracking(),
      isProd: window.location.host.includes('engelvoelkers.com'),
      initialTrackPageViewEvent: false,
      plugins: [SiteTrackingPlugin()],
    });
  }, [tracking]);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider
        translationResolver={translationResolver}
        imageComponent={imageComponent}
        linkComponent={linkComponent}
        searchAlertBaseUrl={searchAlertBaseUrl}
        mapTilerApiKey={mapTilerApiKey}
        isMapEnabled={isMapEnabled}
        friendlyCaptchaSiteKey={friendlyCaptchaSiteKey}
        vercelProtectionBypassToken={vercelProtectionBypassToken}
        mapBoundingBoxFallback={mapBoundingBoxFallback}
        apiConfig={apiConfig}
      >
        <SearchProvider
          initialFilters={initialFilters}
          initialResults={initialResults}
          initialGeoResults={initialGeoResults}
          initialOptions={initialOptions}
          currency={currency}
          measurementSystem={measurementSystem}
          language={language}
          initialPlaceDetails={initialPlaceDetails}
          countryCode={countryCode}
          syncStateWithUrl={syncStateWithUrl}
          onUrlUpdate={events?.onUrlUpdate}
          shopNames={shopNames}
          snowPlowPageType={tracking?.pageType}
        >
          <GlobalStyles />
          <BreakpointProvider>
            <WatchlistProvider watchlist={watchlist} onWatchlistClick={events?.onWatchlistClick}>
              <StyledWrapper>
                <ShopBanner isVisible={isShopBannerVisible} />
                {isFilterBarSticky ? (
                  <StyledStickyHeader>
                    <FilterBar />
                  </StyledStickyHeader>
                ) : (
                  <FilterBar />
                )}
                <SearchResults
                  onIsMapViewChange={events?.onIsMapViewChange}
                  seoPagesLinks={seoPagesLinks}
                  infoRowVariant={infoRowVariant}
                />
              </StyledWrapper>
            </WatchlistProvider>
          </BreakpointProvider>
        </SearchProvider>
      </AppContextProvider>
    </QueryClientProvider>
  );
};
