import { getPlaceDetailsV2, type PlaceResult } from '@generated/search-bff';
import { type ApiConfig, type ApiResult, type GetPlaceLocationDetailsOptions } from '@pkgs/api/types';
import { createSessionToken } from '@pkgs/api/utils';
import { getErrorResult } from '@pkgs/api/utils/error';

/**
 * Fetch detailed location information for a given place ID.
 * @function
 * @param {string} placeId - The unique identifier of the place to fetch details for.
 * @param {Language} GetPlaceLocationDetailsOptions.language - The language that the fetched location information should be in.
 * @param {string} GetPlaceLocationDetailsOptions.sessionToken - Session Token.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<PlaceResult>>} A promise that resolves to either detailed location information or error details.
 *
 * @summary Fetch detailed location information for a given place ID.
 */

export async function getLocationDetails(
  placeId: string,
  { language, sessionToken }: GetPlaceLocationDetailsOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<PlaceResult>> {
  try {
    const result = await getPlaceDetailsV2(
      {
        placeId,
        language,
        sessionToken: sessionToken ?? createSessionToken(),
      },
      apiConfig,
    );

    return {
      status: 'success',
      data: result,
    };
  } catch (error) {
    return getErrorResult(error);
  }
}
