/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-api.envstage.com/_doc/doc.json
*/

import { customInstance } from '../custom-instance';
import type { InputContact } from '../models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Lead contact form to request further information, viewing, callback, etc. for a certain listing
 * @summary Lead Contact request form.
 */
export const listingLead = (listingID: string, inputContact: InputContact, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    { url: `/api/listing/${listingID}/lead`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: inputContact },
    options,
  );
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type ListingLeadResult = NonNullable<Awaited<ReturnType<typeof listingLead>>>;
