import {
  editSearchAlert
} from "./chunk-YYWHXEGH.js";
import {
  getErrorResult
} from "./chunk-TLK7Y73O.js";

// src/editSearchAlert/editSearchAlert.ts
async function editSearchAlert2(searchAlertId, editSearchAlertRequest, apiConfig) {
  try {
    await editSearchAlert(searchAlertId, editSearchAlertRequest, apiConfig);
    return { status: "success", data: void 0 };
  } catch (error) {
    return getErrorResult(error);
  }
}

export {
  editSearchAlert2 as editSearchAlert
};
