import { type BigDecimalRangeDto, type Language, PropertyMarketingType } from '@ev/search-modules-api';
import { useEffect, useState } from 'react';

import { useAppContext } from '../../hooks/useAppContext';
import { validateRangeValues } from '../../utils/numberFormatters';
import { formatRangeLabel } from '../../utils/stringUtils';
import { RangeDropdown } from '../RangeDropdown/RangeDropdown';
import { PriceInput } from '../RangeInputs/PriceInput';
import { type FilterDropdownProps } from './FilterDropdown.types';

type PriceFilterDropdownProps = FilterDropdownProps & {
  marketingType: PropertyMarketingType;
  currencySymbol: string;
  language: Language;
  'data-test-id'?: string;
};

export const PriceFilterDropdown = ({
  marketingType,
  value,
  currencySymbol,
  language,
  className,
  onChange,
  'data-test-id': dataTestId,
}: PriceFilterDropdownProps) => {
  const { t } = useAppContext();
  const [range, setRange] = useState<BigDecimalRangeDto | undefined>(value ? validateRangeValues(value) : undefined);
  const priceLabel = marketingType === PropertyMarketingType.rent ? t('search.searchbar.rent') : t('search.searchbar.price');

  useEffect(() => {
    setRange(value ? validateRangeValues(value) : undefined);
  }, [value]);

  return (
    <RangeDropdown
      className={className}
      label={priceLabel}
      value={value ? formatRangeLabel(value, currencySymbol, language, true) : ''}
      onDone={() => {
        range && onChange({ price: { ...range } });
      }}
      onReset={() => {
        onChange({ price: undefined });
        setRange(undefined);
      }}
      data-test-id={dataTestId ? `${dataTestId}_dropdown` : 'search-components_price-filter_dropdown'}
    >
      <PriceInput
        hiddenLabel={true}
        currencySymbol={currencySymbol}
        marketingType={marketingType}
        defaultRange={value}
        language={language}
        onChange={setRange}
        data-test-id={dataTestId}
      />
    </RangeDropdown>
  );
};
