import { type ApiConfig, type BoundingBoxDto, SearchApi } from '@ev/search-modules-api';
import { type LinkProps as NextLinkProps } from 'next/link';
import { createContext, type PropsWithChildren, useMemo } from 'react';

import { DefaultImageComponent, type ImageComponent } from '../components/Image/DefaultImageComponent';
import { NEW_SEARCH_URL_MAP } from '../consts/consts';

export type TFunction = (...args: unknown[]) => string;

export type LinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> &
  NextLinkProps & {
    children: React.ReactNode;
    href: string;
  };

export type LinkComponent = (props: LinkProps) => JSX.Element;

export const DefaultLinkComponent: LinkComponent = ({ children, href, onClick, ...props }: LinkProps) => {
  return (
    <a {...props} onClick={onClick} href={href}>
      {children}
    </a>
  );
};

export type AppContextProviderType = {
  ImageComponent: ImageComponent;
  t: TFunction;
  LinkComponent: LinkComponent;
  newSearchUrl: string;
  mapTilerApiKey?: string;
  isMapEnabled: boolean;
  friendlyCaptchaSiteKey: string;
  vercelProtectionBypassToken?: string;
  mapBoundingBoxFallback?: BoundingBoxDto;
  api: SearchApi;
};

const initialState: AppContextProviderType = {
  t: () => '',
  ImageComponent: DefaultImageComponent,
  LinkComponent: DefaultLinkComponent,
  newSearchUrl: '',
  friendlyCaptchaSiteKey: '',
  isMapEnabled: false,
  api: {} as SearchApi,
};

export const AppContext = createContext<AppContextProviderType>(initialState);

export type AppContextProviderProps = PropsWithChildren<{
  imageComponent?: ImageComponent;
  translationResolver: TFunction;
  linkComponent?: LinkComponent;
  searchAlertBaseUrl?: string;
  mapTilerApiKey?: string;
  isMapEnabled: boolean;
  friendlyCaptchaSiteKey: string;
  vercelProtectionBypassToken?: string;
  mapBoundingBoxFallback?: BoundingBoxDto;
  apiConfig: ApiConfig;
}>;

export const AppContextProvider = ({
  imageComponent,
  linkComponent,
  translationResolver,
  searchAlertBaseUrl,
  mapTilerApiKey,
  isMapEnabled,
  friendlyCaptchaSiteKey,
  vercelProtectionBypassToken,
  mapBoundingBoxFallback,
  children,
  apiConfig,
}: AppContextProviderProps) => {
  const api = useMemo(() => new SearchApi(apiConfig), [apiConfig]);

  const newSearchUrl = searchAlertBaseUrl || NEW_SEARCH_URL_MAP[api.config.environment];

  const value = useMemo<AppContextProviderType>(() => {
    return {
      t: translationResolver,
      ImageComponent: imageComponent || DefaultImageComponent,
      LinkComponent: linkComponent || DefaultLinkComponent,
      newSearchUrl,
      mapTilerApiKey,
      isMapEnabled,
      friendlyCaptchaSiteKey,
      vercelProtectionBypassToken,
      mapBoundingBoxFallback,
      api,
    };
  }, [
    translationResolver,
    imageComponent,
    linkComponent,
    newSearchUrl,
    mapTilerApiKey,
    isMapEnabled,
    friendlyCaptchaSiteKey,
    vercelProtectionBypassToken,
    mapBoundingBoxFallback,
    api,
  ]);
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
