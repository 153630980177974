import {
  type CountryCode,
  type Currency,
  DEFAULT_IMAGE,
  getUrlWithParams,
  type Language,
  type MeasurementSystem,
  type Property,
} from '@ev/search-modules-api';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useCarouselActions } from '@pkgs/components/hooks/useCarouselActions';
import { usePrice } from '@pkgs/components/hooks/usePrice';
import { type LinkComponent, type TFunction } from '@pkgs/components/providers/AppContextProvider';
import { type DOMAttributes, forwardRef, type MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { VisuallyHidden } from 'react-aria';

import { useQueryParams } from '../../hooks/useQueryParams';
import { ViewportSize as viewportSizes } from '../../utils/mediaQueries';
import { getCardAttributes, getVirtualTourType } from '../../utils/propertyCardUtils';
import { Badge, type IBadgeProps } from '../Badge/Badge';
import { CarouselImage } from '../Carousel';
import { Carousel } from '../Carousel/Carousel';
import { type ImageComponent } from '../Image/DefaultImageComponent';
import { VirtualTourIcon } from '../VirtualTourIcon/VirtualTourIcon';
import {
  LinkContainer,
  StyledArticle,
  StyledAttribute,
  StyledAttributeItem,
  StyledAttributes,
  StyledBadgesContainer,
  StyledContent,
  StyledConvertedPrice,
  StyledFavourite,
  StyledHeadline,
  StyledImageWrapper,
  StyledLocation,
  StyledPrice,
  StyledPriceContainer,
  StyledPriceLabel,
  StyledPriceValueContainer,
} from './SearchResultCard.styled';

export type Attribute = {
  id: string;
  label: string;
};

export type SearchResultsCardVariant = 'default' | 'preview';

type DOMEventHandlers = {
  [K in keyof DOMAttributes<HTMLElement> as K extends `on${string}` ? K : never]: DOMAttributes<HTMLElement>[K];
};

export type SearchResultsCardProps = {
  property: Property;
  preloadImage?: boolean;
  badges?: Array<Pick<IBadgeProps, 'label' | 'variant'>>;
  attributes?: Attribute[];
  isInWatchlist: boolean;
  onWatchlistClick?: (property: Property) => void;
  onClickPropertyCardLink?: (exposeUrl: string) => void;
  className?: string;
  fallbackImage?: string;
  imageComponent?: ImageComponent;
  linkComponent?: LinkComponent;
  currency: Currency;
  measurementSystem: MeasurementSystem;
  language: Language;
  translationResolver: TFunction;
  countryCode?: CountryCode;
  variant?: SearchResultsCardVariant;
} & DOMEventHandlers;

export const SearchResultCard = forwardRef<HTMLElement, SearchResultsCardProps>(
  (
    {
      property,
      preloadImage,
      badges: additionalBadges,
      attributes: additionalAttributes,
      currency,
      isInWatchlist,
      onWatchlistClick,
      onClickPropertyCardLink,
      className,
      fallbackImage,
      imageComponent,
      linkComponent,
      measurementSystem,
      language,
      translationResolver: t,
      countryCode,
      variant = 'default',
      ...eventHandlerProps
    }: SearchResultsCardProps,
    ref,
  ) => {
    const trackingQueryParams = useQueryParams();
    const { images, profile, exposePath, vtourURL, id, isDevelopmentProject, isNew, businessArea, formattedLocation } = property;
    const [link, setLink] = useState<{ href: string; label: string }>();
    const { ImageComponent, LinkComponent } = useAppContext();
    const linkRef = useRef<HTMLLabelElement | null>(null);
    const carouselActions = useCarouselActions();

    const headline = profile?.title ?? '';
    const Link = linkComponent ?? LinkComponent;
    const { slidesInView } = carouselActions;
    const { price, convertedPrice, priceLabel } = usePrice({
      property,
      language,
      currency,
      translationResolver: t,
    });

    const badges = useMemo(() => {
      const extendedBadges = [...(additionalBadges ?? [])];

      if (isNew) {
        extendedBadges.push({
          label: t('search.searchResults.chip.label.new'),
          variant: 'new',
        });
      }
      if (isDevelopmentProject) {
        extendedBadges.push({
          label: t('search.searchResults.chip.label.development'),
          variant: 'development',
        });
      }

      return extendedBadges;
    }, [additionalBadges, isNew, isDevelopmentProject, t]);

    const propertyType = isDevelopmentProject || !businessArea ? 'development' : businessArea;

    const attributes = [...(additionalAttributes ?? []), ...getCardAttributes(propertyType, property, t, language, measurementSystem)];

    const imageList = useMemo(() => {
      return images.length > 0 ? images : [{ url: DEFAULT_IMAGE, title: 'default image' }];
    }, [images]);

    useEffect(() => {
      const url = new URL(window.location.href);
      url.pathname = `${countryCode ? `/${countryCode.toLowerCase()}` : ''}/${language}${exposePath}`;
      setLink({
        href: getUrlWithParams(url, trackingQueryParams ?? {}),
        label: headline,
      });
    }, []);

    const handlePropertyCardLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      onClickPropertyCardLink?.(link?.href ?? '');
    };

    return (
      <StyledArticle
        {...eventHandlerProps}
        className={className}
        data-test-id={`search-components_result-card_${id}`}
        onClick={() => linkRef.current?.click()}
        $variant={variant}
        tabIndex={0}
        ref={ref}
      >
        <StyledImageWrapper>
          <Carousel carouselActions={carouselActions}>
            {imageList.map((img, index) => (
              <CarouselImage
                key={index}
                index={index}
                src={img.url}
                sizes={`(max-width: ${viewportSizes.TABLET_PORTRAIT}px) 100vw, (max-width: ${viewportSizes.LAPTOP}) 50vw, 33vw`}
                imageStyles={{ borderRadius: 'var(--border-radius-s) var(--border-radius-s) 0 0' }}
                inView={slidesInView.includes(index)}
                imageComponent={imageComponent ?? ImageComponent}
                preloadImage={preloadImage}
                width={360}
                height={240}
                fallbackImage={fallbackImage}
                aspectRatio={variant === 'preview' ? '16/9' : '3/2'}
                alt={headline}
              />
            ))}
          </Carousel>
          {badges.length > 0 && (
            <StyledBadgesContainer>
              {badges.map((badge) => {
                return (
                  badge.label && (
                    <Badge
                      key={badge.label}
                      label={badge.label}
                      variant={badge.variant}
                      data-test-id={`search-components_result-card_badge_${badge.label}`}
                    />
                  )
                );
              })}
            </StyledBadgesContainer>
          )}
          {/* eslint-disable-next-line jsx-expressions/strict-logical-expressions */}
          {vtourURL && (
            <VirtualTourIcon type={getVirtualTourType(vtourURL)} data-test-id="search-components_result-card_virtual-tour-icon" />
          )}
        </StyledImageWrapper>
        <StyledContent $variant={variant}>
          {variant !== 'preview' && (
            <StyledLocation forwardedAs="p" size="m" text={formattedLocation} data-test-id="search-components_result-card_location" />
          )}
          <StyledHeadline
            size="h6"
            forwardedAs="h3"
            label={headline}
            $variant={variant}
            data-test-id="search-components_result-card_headline"
          />
          <StyledPriceContainer $variant={variant}>
            {Boolean(priceLabel) && (
              <StyledPriceLabel forwardedAs="p" size="m" text={priceLabel} data-test-id="search-components_result-card_price-label" />
            )}
            <StyledPriceValueContainer>
              <StyledPrice size="xl" text={price} data-test-id="search-components_result-card_price" />
              {Boolean(convertedPrice) && (
                <StyledConvertedPrice size="m" text={`~ ${convertedPrice}`} data-test-id="search-components_result-card_converted-price" />
              )}
            </StyledPriceValueContainer>
          </StyledPriceContainer>
          <StyledAttributes $variant={variant}>
            {attributes.map((attribute) => (
              <StyledAttribute key={attribute.id}>
                <StyledAttributeItem
                  size="m"
                  forwardedAs="span"
                  text={attribute.label}
                  data-test-id={`search-components_result-card_attribute_${attribute.id}`}
                />
              </StyledAttribute>
            ))}
          </StyledAttributes>
          <LinkContainer>
            <Link onClick={handlePropertyCardLinkClick} href={link?.href ?? ''} target={variant === 'preview' ? '_blank' : '_self'}>
              <VisuallyHidden>
                <span ref={linkRef}>{link?.label}</span>
              </VisuallyHidden>
            </Link>
          </LinkContainer>
        </StyledContent>
        {onWatchlistClick && (
          <StyledFavourite
            property={property}
            iconButtonVariant="tertiaryAlt"
            size="xsmall"
            variant="filled"
            isInWatchlist={isInWatchlist}
            onWatchlistClick={onWatchlistClick}
            data-test-id="search-components_result-card_favourite-button"
          />
        )}
      </StyledArticle>
    );
  },
);

SearchResultCard.displayName = 'SearchResultCard';
