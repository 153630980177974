import { Button } from '@spearhead/components/elements/Button/Button';
import { css, styled } from 'styled-components';

import { from, to } from '../../utils/mediaQueries';
import { skeletonAnimation } from '../Skeleton/Skeleton.styles';

export const StyledResultsInfoWrapper = styled.div``;

export const StyledResultsContainer = styled.div<{ $isMapView: boolean }>`
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--distance-static-l);

  ${({ $isMapView }) =>
    $isMapView &&
    css`
      gap: var(--distance-static-m);
    `}

  @media screen and (${from.laptop}) {
    ${({ $isMapView }) =>
      $isMapView &&
      css`
        grid-template-columns: repeat(2, minmax(0, 1fr));
      `}
  }
`;

export const StyledResultsList = styled.div<{ $isMapView: boolean }>`
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--distance-static-l);

  ${({ $isMapView }) =>
    $isMapView &&
    css`
      gap: var(--distance-static-m);
    `}

  @media screen and (${from.tabletPortrait}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media screen and (${from.laptop}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  ${({ $isMapView }) =>
    $isMapView &&
    css`
      @media screen and (${from.tabletPortrait}) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
      @media screen and (${from.laptop}) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    `}
`;

export const StyledResultsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-m);
  align-items: center;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-l);
  margin-inline: var(--distance-static-m);

  @media screen and (${from.tabletLandscape}) {
    margin-inline: var(--distance-static-xxl);
  }
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  margin-top: var(--distance-static-xs);
`;

export const StyledMapViewButton = styled(Button)`
  position: fixed;
  bottom: var(--distance-static-s);
  left: 50%;
  transform: translateX(-50%);
  padding-block: 6px;
  box-shadow: var(--shadow-m);
  z-index: 1;

  @media screen and (${from.laptop}) {
    display: none;
  }
`;

export const StyledMapFallbackSkeleton = styled.div`
  background: var(--color-desert-storm);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  ${skeletonAnimation}
`;

export const StyledMapFallbackWrapper = styled.div`
  position: sticky;
  top: var(--filter-bar-height);
  height: calc(100dvh - var(--filter-bar-height));
  background: var(--color-light);

  @media screen and (${from.laptop}) {
    margin-right: calc(-1 * var(--distance-static-xxl));
    margin-top: var(--distance-static-s);
    top: calc(var(--filter-bar-height) + var(--header-height) + var(--distance-static-s));
    height: calc(100dvh - var(--filter-bar-height) - var(--header-height) - var(--distance-static-s));
  }

  @media screen and (${to.laptop}) {
    position: fixed;
    left: 0;
    top: calc(var(--header-height, var(--distance-static-4xl)));
    height: calc(100% - var(--header-height, var(--distance-static-4xl)));
    width: 100dvw;
    z-index: 100;
  }
`;
