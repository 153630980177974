import {
  type BigDecimalRangeDto,
  type BusinessArea,
  type Currency,
  isRangeFilter,
  type Language,
  type PropertyMarketingType,
  type PropertyType,
  type StrictBaseOptions,
  type SurfaceFilterKey,
  UnitSymbolMap,
} from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';
import { capitalize } from 'lodash-es';

import { SearchAlertFrequencyEnum } from '../hooks/useSearchAlert';
import { type TFunction } from '../providers/AppContextProvider';
import { type FilterRefinement } from '../types/types';
import { formatNumber, formatPriceWithoutDecimals } from './numberFormatters';
import { isEmptyObject } from './searchStateUtils';

export function getSecondsByFrequency(frequency: SearchAlertFrequencyEnum): 0 | 86_400 | 604_800 {
  switch (frequency) {
    case SearchAlertFrequencyEnum.DAILY:
      return 86_400;
    case SearchAlertFrequencyEnum.WEEKLY:
      return 604_800;
    case SearchAlertFrequencyEnum.INSTANTLY:
      return 0;
    default:
      return 0;
  }
}

function formatRangeValue(rangeValue: number, language: Language, currency?: Currency, isYear?: boolean, isCurrency?: boolean) {
  if (isYear) {
    return rangeValue;
  }
  return isCurrency
    ? formatPriceWithoutDecimals(rangeValue, language, currency ?? 'EUR')
    : formatNumber(rangeValue, language, {
        useGrouping: true,
      });
}

function getRangeLabel(params: {
  t: TFunction;
  language: Language;
  range?: BigDecimalRangeDto;
  isCurrency?: boolean;
  isYear?: boolean;
  currency?: Currency;
}): string {
  const { t, language, range, isCurrency, isYear, currency } = params;
  if (!range?.min && !range?.max) {
    return '';
  }

  const max = range.max ? formatRangeValue(range.max, language, currency, isYear, isCurrency) : '';
  const min = range.min ? formatRangeValue(range.min, language, currency, isYear, isCurrency) : '';

  if (min && max) {
    return `${min} - ${max}`;
  } else if (min) {
    return `${t('search.searchbar.minLabel')} ${min}`;
  } else if (max) {
    return `${t('search.searchbar.maxLabel')} ${max}`;
  }
  return '';
}

export function getFilterDescription(filters: SearchModuleFilters, placeName: string, t: TFunction, options: StrictBaseOptions): string {
  const { marketingType, propertyType, businessArea, price, placeId, livingSurface, rooms, ...rest } = filters;

  const { language, currency } = options;

  const marketingLabel = t(`search.searchbar.${marketingType.toLowerCase()}`);

  const propertyLabel = getPropertyLabel(propertyType ?? businessArea, t);

  const livingAreaLabel = getSurfaceLabel({
    surfaceValue: livingSurface,
    type: 'livingSurface',
    t,
    options,
  });
  const priceLabel = getPriceLabel({
    price,
    marketingType: marketingType,
    t,
    currency,
    language,
  });
  const roomsLabel = getRoomsLabel(filters, language, t);
  const restFilterAttributes = mapFilterAttributesToString({
    filters: rest,
    t,
    options,
  });

  const description = [
    marketingLabel,
    propertyLabel,
    capitalize(placeName),
    livingAreaLabel,
    roomsLabel,
    priceLabel,
    ...restFilterAttributes,
  ];

  return description.filter(Boolean).join(', ');
}

export function mapFilterAttributesToString(params: { filters: FilterRefinement; t: TFunction; options: StrictBaseOptions }): string[] {
  const { filters, t, options } = params;
  const { currency, language } = options;

  const attributesWithValues = Object.entries(filters).filter(([, value]) => {
    return value && !isEmptyObject(value);
  });

  const featureLabels = attributesWithValues.reduce<string[]>((labels, [key, value]) => {
    if (value === true && key !== 'searchAlert') {
      const featureLabel = getFeatureLabel(key, t);
      labels.push(featureLabel);
    }
    return labels;
  }, []);

  const attributesWithRange = attributesWithValues.reduce<string[]>((result, [key, value]) => {
    if (isRangeFilter(value)) {
      const attribute = key.toLowerCase().includes('surface')
        ? getSurfaceLabel({
            surfaceValue: value,
            type: key as SurfaceFilterKey,
            t,
            options,
          })
        : getAttributeRangeLabel({
            key: key as keyof SearchModuleFilters,
            range: value,
            t,
            currency,
            language,
          });

      result.push(attribute);
    }
    return result;
  }, []);

  return [...attributesWithRange, ...featureLabels];
}

function getRoomsLabel(filters: FilterRefinement, language: Language, t: TFunction): string {
  const rangeLabel = getRangeLabel({
    t,
    language,
    range: filters.rooms,
  });

  return rangeLabel ? `${rangeLabel} ${t('search.searchbar.rooms')}` : '';
}

function getPriceLabel(params: {
  price?: BigDecimalRangeDto;
  marketingType?: PropertyMarketingType;
  t: TFunction;
  currency: Currency;
  language: Language;
}): string {
  const { price, marketingType, t, currency, language } = params;

  if (!price || !marketingType) return '';

  const rangeLabel = getRangeLabel({
    t,
    language,
    range: price,
    isCurrency: true,
    isYear: false,
    currency,
  });
  const saleRentLabel = marketingType === 'rent' ? t('search.searchbar.rent') : t('search.searchbar.price');

  return rangeLabel ? `${saleRentLabel} ${rangeLabel}` : '';
}

export function getPropertyLabel(type: PropertyType | BusinessArea, t: TFunction): string {
  return t(`search.searchbar.advFilter.propertyType.${type}`);
}

export function getSurfaceLabel(params: {
  surfaceValue?: BigDecimalRangeDto;
  type: SurfaceFilterKey;
  t: TFunction;
  options: StrictBaseOptions;
}): string {
  const {
    surfaceValue,
    type,
    t,
    options: { language, measurementSystem, currency },
  } = params;
  if (!surfaceValue) return '';

  let label = '';

  switch (type) {
    case 'livingSurface':
      label = t('search.searchbar.livingArea');
      break;
    case 'plotSurface':
      label = t('search.searchbar.advFilter.plotSurface');
      break;
    case 'totalSurface':
      label = t('search.searchbar.totalSurface');
      break;
  }
  const rangeLabel = getRangeLabel({
    t,
    language,
    range: surfaceValue,
    isCurrency: false,
    isYear: false,
    currency,
  });
  const unitSymbol = UnitSymbolMap[measurementSystem];

  return rangeLabel ? `${label} ${rangeLabel} ${unitSymbol}` : '';
}

function getFeatureLabel(feature: string, t: TFunction): string {
  return feature === 'hasVtour' ? t('search.searchbar.advFilter.vtour') : t(`search.searchbar.advFilter.features.${feature}`);
}

function getAttributeRangeLabel(params: {
  key: keyof SearchModuleFilters;
  range: BigDecimalRangeDto;
  t: TFunction;
  language: Language;
  currency: Currency;
}): string {
  const { key, range, t, language, currency } = params;
  const attributeLabel = t(`search.searchbar.advFilter.${key}`);
  const rangeLabel = getRangeLabel({
    t,
    language,
    range,
    isCurrency: false,
    isYear: key === 'constructionYear',
    currency,
  });
  return `${attributeLabel} ${rangeLabel}`;
}
