import {
  confirm as generatedConfirmSearchAlert,
  type ConfirmResult as GeneratedConfirmResult,
} from '@generated/search-bff/controllers/search-alert/search-alert';
import { type ApiConfig, type ApiResult } from '@pkgs/api/types';
import { getErrorResult } from '@pkgs/api/utils';

/**
 * Confirm a search alert
 * @function
 * @param {string} searchAlertId - The unique identifier of the search alert to be confirmed.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<void>>} A promise that resolves to an api result object without any data.
 *
 * @summary Confirm a search alert
 */
export async function confirmSearchAlert(searchAlertId: string, apiConfig: ApiConfig): Promise<ApiResult<void>> {
  try {
    await generatedConfirmSearchAlert(searchAlertId, apiConfig);
    return { status: 'success', data: undefined };
  } catch (error) {
    return getErrorResult(error);
  }
}

export type ConfirmResult = GeneratedConfirmResult;
