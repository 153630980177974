import {
  type CountryCode,
  type Currency,
  type DeepPartial,
  type FallbackSearch,
  type GetPropertiesOptions,
  type Language,
  type MeasurementSystem,
  type PlaceLocation,
  type PropertiesResponse,
  type SearchGeoPointResponse,
} from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useGeoCoordinates } from '@pkgs/components/hooks/useGeoCoordinates';
import { usePlaceDetails } from '@pkgs/components/hooks/usePlaceDetails';
import { DEFAULT_FILTERS, DEFAULT_OPTIONS, useSearchState } from '@pkgs/components/hooks/useSearchState';
import { useUrlState } from '@pkgs/components/hooks/useUrlState';
import { getParsedShopName } from '@pkgs/components/utils/propertyDataUtils';
import { type PageType } from '@pkgs/components/utils/tracking';
import { createContext, type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useDebounce, useEffectOnce } from 'react-use';

import { RESULTS_PLACEHOLDER, useProperties } from '../hooks/useProperties';
import { scrollToTop } from '../utils/scrollToTop';

export type SearchProviderType = {
  filters: SearchModuleFilters;
  options: Required<GetPropertiesOptions>;
  updateFilters: (revision: DeepPartial<SearchModuleFilters>) => void;
  results: PropertiesResponse;
  geoResults: SearchGeoPointResponse;
  updateOptions: (revision: DeepPartial<GetPropertiesOptions>) => void;
  updatePlaceName: (name: string | undefined) => void;
  countryCode?: CountryCode;
  placeDetails: PlaceLocation;
  isFetching: boolean;
  isGeoFetching: boolean;
  isError: boolean;
  shopNames?: string[];
  isPartnerShops?: boolean;
  noLocationOrShopFilter?: boolean;
  snowPlowPageType?: PageType;
  isMapSearch: boolean;
};

export const initialState: SearchProviderType = {
  filters: DEFAULT_FILTERS,
  options: DEFAULT_OPTIONS,
  results: RESULTS_PLACEHOLDER,
  geoResults: { points: [], enableMap: false },
  placeDetails: {},
  isFetching: true,
  isGeoFetching: true,
  isError: false,
  updateFilters: () => {},
  updateOptions: () => {},
  updatePlaceName: () => {},
  shopNames: [],
  isMapSearch: false,
};

export const SearchContext = createContext<SearchProviderType>(initialState);

export type SearchProviderProps = PropsWithChildren<{
  initialFilters?: SearchModuleFilters;
  initialOptions?: Omit<GetPropertiesOptions, 'currency' | 'measurementSystem' | 'language'>;
  currency: Currency;
  measurementSystem: MeasurementSystem;
  language: Language;
  initialResults?: PropertiesResponse;
  initialGeoResults?: SearchGeoPointResponse;
  countryCode?: CountryCode;
  initialPlaceDetails?: PlaceLocation;
  syncStateWithUrl?: boolean;
  onUrlUpdate?: (url: string) => void;
  shopNames?: string[];
  fallbackSearch?: FallbackSearch;
  snowPlowPageType?: PageType;
}>;

export const SearchProvider = ({ children, ...props }: SearchProviderProps) => {
  const { initialFilters, countryCode, shopNames, snowPlowPageType, initialPlaceDetails } = props;

  const { isMapEnabled } = useAppContext();

  const { filters, replaceFilters, options, replaceOptions, updateFilters, updateOptions } = useSearchState(props);

  const { placeDetails } = usePlaceDetails({
    ...props,
    placeId: filters.placeId,
    language: options.language,
  });

  // placeDetails includes the name of the place already but it can differ from the autosuggest place name which needs to be preserved in case its provided
  const [placeName, updatePlaceName] = useState<string | undefined>(initialPlaceDetails?.name ?? placeDetails.name);

  const { data: results = RESULTS_PLACEHOLDER, isFetching, isError } = useProperties({ ...props, filters, options, placeDetails });
  const { data: geoResults = {}, isFetching: isGeoFetching } = useGeoCoordinates({ ...props, filters, options });

  const { updateUrl, setStateFromUrl } = useUrlState({
    ...props,
    filters,
    options,
    replaceFilters,
    replaceOptions,
    placeName,
    updatePlaceName,
  });

  useDebounce(updateUrl, 50, [filters, options]);
  useEffect(scrollToTop, [results]);

  useEffectOnce(() => {
    // disable browser scroll restoration since we handle it manually
    history.scrollRestoration = 'manual';
    // handle browser back/forward navigation
    window.addEventListener('popstate', setStateFromUrl);

    // if initialFilters are provided, we assume that they were already parsed from the URL
    // on the server side, so we don't need to parse them again
    if (!initialFilters) setStateFromUrl();

    updateUrl();

    return () => {
      history.scrollRestoration = 'auto';
      window.removeEventListener('popstate', setStateFromUrl);
    };
  });

  const isPartnerShops = Boolean(filters.shopIds?.length || filters.masterDataShopIds?.length);

  const noLocationOrShopFilter = !(Boolean(filters.placeId) || Boolean(filters.shopIds) || Boolean(filters.boundingBox));

  const isMapSearch = isMapEnabled && Boolean(filters.boundingBox);

  // if the search was triggered via the map, we dont want to return stale placeDetails data
  // when searching via the map, we only need the boundingBox and the placeName
  const conditionalPlaceDetails = useMemo(
    () => (isMapSearch ? { name: placeName, boundingBox: filters.boundingBox } : { ...placeDetails, name: placeName }),
    [placeDetails, placeName, filters.boundingBox, isMapSearch],
  );

  const value = useMemo<SearchProviderType>(() => {
    return {
      filters,
      options,
      results,
      geoResults,
      updateFilters,
      updateOptions,
      updatePlaceName,
      isFetching,
      isGeoFetching,
      countryCode,
      placeDetails: conditionalPlaceDetails,
      isError,
      shopNames: shopNames?.map((shopName) => getParsedShopName(shopName)),
      isPartnerShops,
      noLocationOrShopFilter,
      snowPlowPageType,
      isMapSearch,
    };
  }, [
    filters,
    options,
    results,
    geoResults,
    updateFilters,
    updateOptions,
    isFetching,
    isGeoFetching,
    countryCode,
    conditionalPlaceDetails,
    isError,
    shopNames,
    isPartnerShops,
    noLocationOrShopFilter,
    snowPlowPageType,
    isMapSearch,
  ]);
  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
