import {
  type BusinessDivisionType,
  getDivisionContext,
  getPageContext,
  getShopContext,
  getUserContext,
  trackSearchAlertCreationEvent,
} from '@ev/snowplow-library';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { type SearchAlertFrequencyEnum, useSearchAlert } from '@pkgs/components/hooks/useSearchAlert';
import { getFilterDescription } from '@pkgs/components/utils/searchAlertUtils';
import { PageType, trackSearchAlertCreatedInDataLayer } from '@pkgs/components/utils/tracking';
import { type DOMProps } from '@react-types/shared';
import { Button } from '@spearhead/components/elements/Button/Button';
import { Copy } from '@spearhead/components/elements/Copy/Copy';
import { Headline } from '@spearhead/components/elements/Headline/Headline';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { type OverlayTriggerState } from 'react-stately';

import { FrequencyPicker } from '../FrequencyPicker/FrequencyPicker';
import { ConsentCheckbox } from './ConsentCheckbox';
import { EmailInput } from './EmailInput';
import { NameInput } from './NameInput';
import {
  StyledFilterSummary,
  StyledForm,
  StyledHeader,
  StyledModal,
  StyledModalContent,
  StyledModalFooter,
  StyledSubmitButton,
} from './SearchAlert.styled';

const SearchAlertStatusDisplay = lazy(() => import('./SearchAlertStatusDisplay'));

type SearchAlertFormFields = {
  name: string;
  email: string;
  frequency: SearchAlertFrequencyEnum;
};

type SearchAlertModalProps = {
  state: OverlayTriggerState;
  overlayProps: DOMProps;
};

export const SearchAlertModal = ({ state, overlayProps }: SearchAlertModalProps) => {
  const { t } = useAppContext();
  const { mutate: createSearchAlert, status } = useSearchAlert();

  const formRef = useRef<HTMLFormElement>(null);
  const { filters, options, placeDetails, countryCode, snowPlowPageType } = useSearch();
  const [isProcessing, setIsProcessing] = useState(false);

  const [formValues, setFormValues] = useState<SearchAlertFormFields | undefined>();

  const { language, currency, measurementSystem } = options;

  useEffect(() => {
    if (status === 'success') {
      const placeName = placeDetails.name ?? '';

      placeName && trackSearchAlertCreatedInDataLayer(placeName);

      trackSearchAlertCreationEvent({
        source: 'create-search-alert-dialog',
        contexts: [
          getUserContext({ email: formValues?.email ?? '' }),
          getShopContext({ id: filters.shopIds?.[0] }),
          getPageContext({
            isHQPage: true,
            type: snowPlowPageType ?? PageType.SEARCH_RESULT,
            country_code: filters.countryCode,
            page_language: language,
          }),
          getDivisionContext({
            name: filters.businessArea as BusinessDivisionType,
          }),
        ],
      });
    }
  }, [filters, formValues, language, placeDetails.name, status]);

  const filterSummary = getFilterDescription(filters, placeDetails.name ?? '', t, options);

  const handleSubmit = async () => {
    if (!formRef.current?.checkValidity()) {
      const firstInvalidInput = formRef.current?.querySelector<HTMLInputElement>('input:invalid');
      firstInvalidInput?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      firstInvalidInput?.focus({ preventScroll: true });
      return;
    }

    const formData = new FormData(formRef.current);
    setFormValues(Object.fromEntries(formData.entries()) as SearchAlertFormFields);
    setIsProcessing(true);
  };

  const sendFormRequest = async (solution: string) => {
    if (!formRef.current || !formValues) return;
    const placeName = placeDetails.name ?? '';

    createSearchAlert({
      ...formValues,
      newsletterConsent: true,
      filterDescriptionText: filterSummary,
      filters,
      friendlyCaptchaSolution: solution,
      language,
      countryCode,
      placeName,
      currency,
      measurementSystem,
    });
  };

  const defaultName = `${t(`search.searchAlert.create.name.placeholder.${filters.marketingType}.${filters.businessArea}`, {
    placeName: placeDetails.name ?? '',
    defaultValue: '',
  })}`;

  return (
    <StyledModal
      state={state}
      overlayProps={overlayProps}
      hasCloseButton={status !== 'success'}
      data-test-id="search-components_search-alert-modal"
    >
      <StyledModalContent $hasMargin={isProcessing}>
        <StyledForm ref={formRef} tabIndex={0} $hidden={isProcessing} data-test-id="search-components_search-alert-creation-form">
          <StyledHeader>
            <Headline size="h5" as="h5" label={t('search.searchAlert.create.label')} />
            <Copy size="l" text={t('search.searchAlert.create.subtitle')} />
          </StyledHeader>
          <StyledFilterSummary
            size="l"
            text={filterSummary}
            data-test-id="search-components_search-alert-creation-form_filter-description"
          />
          <NameInput defaultValue={defaultName} />
          <EmailInput />
          <FrequencyPicker />
          <ConsentCheckbox />
        </StyledForm>
        {isProcessing && (
          <Suspense>
            <SearchAlertStatusDisplay status={status} onSolution={sendFormRequest} onRequestClose={state.close} />
          </Suspense>
        )}
      </StyledModalContent>
      {!isProcessing && (
        <StyledModalFooter>
          <Button
            size="m"
            variant="tertiary"
            label={t('search.common.cancel')}
            onClick={state.close}
            data-test-id="search-components_search-alert-creation-form_cancel-button"
          />
          <StyledSubmitButton
            size="m"
            type="submit"
            label={t('search.searchAlert.create.label')}
            onClick={handleSubmit}
            formNoValidate
            data-test-id="search-components_search-alert-creation-form_submit-button"
            iconPosition="withoutIcon"
          />
        </StyledModalFooter>
      )}
    </StyledModal>
  );
};
export default SearchAlertModal;
