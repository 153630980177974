import {
  createSearchAlert as generatedCreateSearchAlertV3,
  createSearchAlert1 as generatedCreateSearchAlertV2,
  type CreateSearchAlert1Result as GeneratedCreateSearchAlertResultV2,
  type CreateSearchAlertResult as GeneratedCreateSearchAlertResultV3,
} from '@generated/search-bff/controllers/search-alert/search-alert';
import { type CreateSearchAlertRequestV2, type CreateSearchAlertRequestV3 } from '@generated/search-bff/models';
import { type ApiConfig } from '@pkgs/api/types';
import { type ApiResult, type BaseOptions, type GetPropertiesFilters } from '@pkgs/api/types';
import { getErrorResult } from '@pkgs/api/utils/error';

import { mapFilters } from '..';

export type CreateSearchAlertResult = GeneratedCreateSearchAlertResultV3 | GeneratedCreateSearchAlertResultV2;

export type CreateSearchAlertPayloadV3 = Omit<CreateSearchAlertRequestV3, 'searchFilter'> & {
  searchFilter: GetPropertiesFilters;
};
export type CreateSearchAlertPayloadV2 = Omit<CreateSearchAlertRequestV2, 'searchFilter'> & {
  searchFilter: GetPropertiesFilters;
};

/**
 * Create a search alert
 * @function
 * @param {CreateSearchAlertPayload} payload - The payload with required fields to create a search alert.
 * @param {BaseOptions} options - Options to customize the search: language, currency, and measurement unit.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<CreateSearchAlertResult>>} A promise that resolves to a create search alert result containing the created search alert id and pub sub message id.
 *
 * @summary Create a search alert
 */
export async function createSearchAlert(
  payload: CreateSearchAlertPayloadV2 | CreateSearchAlertPayloadV3,
  options: BaseOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<CreateSearchAlertResult>> {
  try {
    let request;

    // ensure backwards compatibility with v2 for autogen
    if ('version' in payload && payload.version === 'v2') {
      const requestPayload: CreateSearchAlertRequestV2 = {
        ...(payload as CreateSearchAlertPayloadV2),
        searchFilter: mapFilters(payload.searchFilter, options),
      };
      request = generatedCreateSearchAlertV2(requestPayload, apiConfig);
    } else {
      const requestPayload: CreateSearchAlertRequestV3 = {
        ...(payload as CreateSearchAlertRequestV3),
        searchAlert: {
          ...(payload as CreateSearchAlertPayloadV3).searchAlert,
          searchFilter: mapFilters(payload.searchFilter, options),
        },
      };
      request = generatedCreateSearchAlertV3(requestPayload, apiConfig);
    }

    const response = await request;

    return {
      status: 'success',
      data: response,
    };
  } catch (error) {
    return getErrorResult(error);
  }
}
