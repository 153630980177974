import { type GetPropertiesFilters } from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';

export const mapToFiltersWithoutArrayFields = (filters: GetPropertiesFilters): SearchModuleFilters => {
  const { businessArea, marketingType, propertyType, propertySubType, countryCode, ...restFilters } = filters;

  return {
    ...restFilters,
    businessArea: businessArea[0]!,
    marketingType: marketingType[0]!,
    ...(propertyType && {
      propertyType: propertyType[0],
    }),
    ...(propertySubType && {
      propertySubType: propertySubType[0],
    }),
    ...(countryCode && { countryCode: countryCode[0] }),
  };
};

export const mapToFiltersWithArrayFields = (filters: SearchModuleFilters): GetPropertiesFilters => {
  const { businessArea, marketingType, propertyType, propertySubType, countryCode, ...restFilters } = filters;

  return {
    ...restFilters,
    businessArea: [businessArea],
    marketingType: [marketingType],
    ...(propertyType && { propertyType: [propertyType] }),
    ...(propertySubType && { propertySubType: [propertySubType] }),
    ...(countryCode && { countryCode: [countryCode] }),
  };
};
