import {
  deleteAllSearchAlerts
} from "./chunk-YYWHXEGH.js";
import {
  getErrorResult
} from "./chunk-TLK7Y73O.js";

// src/deleteAllSearchAlert/deleteAllSearchAlert.ts
async function deleteAllSearchAlerts2(accountId, apiConfig) {
  try {
    await deleteAllSearchAlerts(accountId, apiConfig);
    return { status: "success", data: void 0 };
  } catch (error) {
    return getErrorResult(error);
  }
}

export {
  deleteAllSearchAlerts2 as deleteAllSearchAlerts
};
