import { sortByOptions } from '../../consts/consts';
import { useAppContext } from '../../hooks/useAppContext';
import { useSearch } from '../../hooks/useSearch';
import { Select } from '../Select/Select';
import { Skeleton } from '../Skeleton/Skeleton';
import { StyledArrow, StyledContainer } from './SortBySelect.styled';

export const SortBySelect = () => {
  const { t } = useAppContext();
  const { filters, updateFilters, isFetching } = useSearch();
  const sortByItems = sortByOptions
    .filter((item) => {
      return item.tags.includes(filters.businessArea) && item.tags.includes(filters.marketingType);
    })
    .map((item) => ({
      ...item,
      label: t(item.label),
    }));

  const selectOption =
    sortByItems.find((item) => {
      return item.sortingOption === filters.sortingOptions?.[0];
    }) ?? sortByItems[0];

  return (
    <Skeleton loading={isFetching}>
      <StyledContainer>
        <Select
          arrowIcon={<StyledArrow />}
          items={sortByItems}
          defaultSelectedKey={selectOption?.id}
          selectedKey={selectOption?.id}
          placeholder="Select item"
          onSelectionChange={(sortId) => {
            const sortItem = sortByItems.find((item) => item.id === sortId);
            if (!sortItem) return;
            updateFilters({
              sortingOptions: [sortItem.sortingOption],
            });
          }}
          overlayProps={{ placement: 'bottom right' }}
          variant="basicLight"
          hiddenLabel={true}
        />
      </StyledContainer>
    </Skeleton>
  );
};
