import { PropertyMarketingType } from '@ev/search-modules-api';

import { type InputTextSizesProps } from '../../../spearhead/lib/components/elements/InputText/InputText';
import { useAppContext } from '../../hooks/useAppContext';
import { type FilterRefinement } from '../../types/types';
import { Select } from '../Select/Select';

type BuyRentFilterProps = {
  marketingType: PropertyMarketingType;
  hiddenLabel?: boolean;
  size?: InputTextSizesProps;
  className?: string;
  onChange: (arg: FilterRefinement) => void;
  isInModal?: boolean;
  'data-test-id'?: string;
};
export const BuyRentFilter = ({
  marketingType,
  hiddenLabel,
  size,
  className,
  onChange,
  isInModal,
  'data-test-id': dataTestId,
}: BuyRentFilterProps) => {
  const { t } = useAppContext();
  const items = [
    {
      id: PropertyMarketingType.sale,
      label: t('search.common.marketingType.sale'),
    },
    {
      id: PropertyMarketingType.rent,
      label: t('search.common.marketingType.rent'),
    },
  ];
  const handleOnChange = (marketingType: PropertyMarketingType) => {
    onChange({
      marketingType,
      price: undefined,
    });
  };
  return (
    <Select
      className={className}
      items={items}
      onSelectionChange={(value) => handleOnChange(value as PropertyMarketingType)}
      label={t('search.searchbar.advFilter.marketingType.label')}
      placeholder={t('search.searchbar.advFilter.marketingType.label')}
      selectedKey={marketingType}
      name="marketingType"
      hiddenLabel={hiddenLabel}
      size={size}
      isInModal={isInModal}
      data-test-id={dataTestId ?? 'search-components_buy-rent-filter'}
    />
  );
};
