import {
  findAllSearchAlerts as generatedFindAllSearchAlerts,
  type FindAllSearchAlertsResult as GeneratedFindAllSearchAlertsResult,
} from '@generated/search-bff/controllers/search-alert/search-alert';
import { type FindAllSearchAlertsParams, type FindAllSearchAlertsResponseV3 } from '@pkgs/api/models';
import { type ApiConfig, type ApiResult } from '@pkgs/api/types';
import { getErrorResult } from '@pkgs/api/utils';

/**
 * Find all search alerts of an Account
 * @function
 * @param {string} searchAlertId - The unique identifier of the search alert which should be updated.
 * @param {FindAllSearchAlertsParams} params - Object containing the fields of a search alert that should be updated like name or intervalSeconds.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<FindAllSearchAlertsResponse>>} A promise that resolves with all found alerts as well as information about the count and page.
 *
 * @summary Find all search alerts of an Account
 */
export async function findAllSearchAlerts(
  accountId: string,
  params: FindAllSearchAlertsParams,
  apiConfig: ApiConfig,
): Promise<ApiResult<FindAllSearchAlertsResponseV3>> {
  try {
    const response = await generatedFindAllSearchAlerts(accountId, params, apiConfig);
    return { status: 'success', data: response };
  } catch (error) {
    return getErrorResult(error);
  }
}

export type FindAllSearchAlertsResult = GeneratedFindAllSearchAlertsResult;
